<template>
  <div>
    <h3>Overzicht</h3>
    <b-card
      ><div>
        <strong>Afhaallocatie</strong>
        <p>{{ cartCache.WarehouseID }}</p>
      </div>
      <div>
        <strong>Afhaaldatum</strong>
        <p>
          {{ cartCache.DateTimeBusinessStart | moment('DD/MM/YYYY') }}
        </p>
      </div>
      <div>
        <strong>Terugbrengdatum</strong>
        <p>
          {{ cartCache.DateTimeExpectedEnd | moment('DD/MM/YYYY') }}
        </p>
      </div>
    </b-card>
    <div class="w-100">
      <router-link to="/cart" class="float-right pt-2 pb-2"
        >Pas afhaallocatie en ontleendata aan ></router-link
      >
    </div>
    <div class="mt-2">
      <b-table :items="items" :fields="fields"
        ><template v-slot:cell(total)="data">
          <div class="form-group text-right">
            &euro;
            {{
              (Math.round(data.item.totalIncVAT * 100) / 100)
                .toFixed(2)
                .replace('.', ',')
            }}
          </div>
        </template></b-table
      >
      <b-row>
        <b-col cols="9" class="text-right cart-total-price">
          <p>
            <strong>BTW:</strong>
          </p>
        </b-col>
        <b-col cols="3" class="text-right">
          <p>
            &euro;
            {{
              cartCache.TotalVAT.toFixed(2)
                .toString()
                .replace('.', ',')
            }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="9" class="text-right cart-total-price">
          <p>
            <strong>Totaalbedrag:</strong>
          </p>
        </b-col>
        <b-col cols="3" class="text-right">
          <p>
            &euro;
            {{
              cartCache.TotalIncVAT.toFixed(2)
                .toString()
                .replace('.', ',')
            }}
          </p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cartCache: Object,
    items: Array,
    fields: Array
  }
}
</script>
