<template>
  <b-form @submit.prevent="submitForm">
    <b-card class="mb-4 mt-2">
      <div>
        <label
          >Omschrijving activiteit: <span class="text-danger">*</span></label
        >
        <b-form-textarea v-model="CUST_ActivityDescription"  :required="true" />
      </div>
      <div class="mt-2">
        <label>Opmerkingen:</label> <b-form-textarea v-model="notes" />
      </div>

      <div class="mt-2">
        <b-form-checkbox
          v-model="termsAgreed"
          :value="true"
          :unchecked-value="false"
          required
        >
          Ik ga akkoord met
          <a
            href="https://www.west-vlaanderen.be/uitleendienst-reglement"
            target="_blank"
            >reglement</a
          >
          van de provinciale uitleendienst
          <span class="text-danger">*</span>
        </b-form-checkbox>
        <p class="text-danger" v-if="showTermsError">
          Gelieve akkoord te gaan met het reglement voordat u verdergaat
        </p>
      </div>

      <hr />
      <b-button
        class="float-right btn"
        :class="{ 'button-disabled': checkoutLoading }"
        variant="primary"
        type="submit"
        >Aanvraag bevestigen&nbsp;<font-awesome-icon
          v-if="checkoutLoading"
          :disabled="checkoutLoading"
          class="fa-spin"
          far
          :icon="['fad', 'spinner']"
      /></b-button>
      <h2 class="pt-2">Aanvraag om te bevestigen?</h2>
    </b-card>
  </b-form>
</template>

<script>
export default {
  props: {
    CUST_ActivityDescription: String,
    notes: String,
    checkoutLoading: Boolean
  },
  data() {
    return {
      termsAgreed: false,
      showTermsError: false
    }
  },
  watch: {
    notes: function(val) {
      this.$emit('update:notes', val)
    },
    CUST_ActivityDescription: function(val) {
      this.$emit('update:activity', val)
    }
  },
  methods: {
    async submitForm() {
      if (this.termsAgreed) {
        this.showTermsError = false
        this.$emit('submit')
      } else {
        this.showTermsError = true
      }
    }
  }
}
</script>
