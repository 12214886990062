<template>
  <b-card class="mt-4">
    <b-row>
      <b-col cols="6" class="pb-3">
        <label>Voornaam</label>
        <b-form-input v-model="orderer.firstname" disabled></b-form-input>
      </b-col>
      <b-col cols="6" class="pb-3">
        <label>Achternaam</label>
        <b-form-input v-model="orderer.lastname" disabled></b-form-input>
      </b-col>
      <b-col cols="9" class="pb-3">
        <label>Straatnaam</label>
        <b-form-input v-model="orderer.street" disabled></b-form-input>
      </b-col>
      <b-col cols="3">
        <label>Huisnr.</label>
        <b-form-input v-model="orderer.houseNumber" disabled></b-form-input>
      </b-col>
      <b-col cols="4" class="pb-3">
        <label>Postcode</label>
        <b-form-input v-model="orderer.postal" disabled></b-form-input>
      </b-col>
      <b-col cols="8">
        <label>Gemeente</label>
        <b-form-input v-model="orderer.city" disabled></b-form-input>
      </b-col>
      <b-col cols="6" class="pb-3">
        <label>E-mailadres</label>
        <b-form-input v-model="orderer.email" disabled></b-form-input>
      </b-col>
      <b-col cols="6">
        <label>Telefoonnummer</label>
        <b-form-input v-model="orderer.tel" disabled></b-form-input>
      </b-col>
    </b-row>

    <router-link to="/account" class=" pt-2"
      >Pas je account aan om deze gegevens te wijzigen ></router-link
    >
  </b-card>
</template>

<script>
export default {
  props: {
    orderer: Object
  }
}
</script>
