<template>
  <b-container class="page-container">
    <bread-crumbs currentpage="Reserveren" />
    <h1>Reserveren</h1>
    <hr />

    <b-row>
      <b-col md="7" sm="12">
        <h3>Gegevens van de besteller</h3>
        <orderer-info :orderer="orderer" />
      </b-col>
      <b-col md="5" sm="12">
        <overview :cart-cache="cartCache" :items="items" :fields="fields" />
      </b-col>
    </b-row>
    <activity-form
      :CUST_ActivityDescription="CUST_ActivityDescription"
      :notes="notes"
      @update:activity="CUST_ActivityDescription = $event"
      @update:notes="notes = $event"
      :checkoutLoading="checkoutLoading"
      @submit="checkout"
    />
  </b-container>
</template>

<script>
import BreadCrumbs from '@/components/header/BreadCrumbs.vue'
import OrdererInfo from '@/components/checkout/OrdererInfo.vue'
import Overview from '@/components/checkout/Overview.vue'
import ActivityForm from '@/components/checkout/ActivityForm.vue'
import { getCart, checkout } from '@/services/CartService'
import moment from 'moment'

export default {
  components: {
    BreadCrumbs,
    OrdererInfo,
    Overview,
    ActivityForm
  },
  data() {
    return {
      checkoutLoading: false,
      orderer: {
        firstname: '',
        lastname: '',
        street: '',
        houseNumber: '',
        postal: '',
        city: '',
        email: '',
        tel: ''
      },
      reserverationDetails: {
        eventDescription: '',
        eventStartDate: '',
        eventStartHour: '',
        eventAdres: '',
        expectedRise: '',
        files: '',
        extraInfo: ''
      },
      notes: '',
      CUST_ActivityDescription: '',
      items: [],
      retrievalDate: '',
      returnDate: '',
      warehouse: '',
      fields: [
        {
          key: 'item',
          label: 'Item'
        },
        {
          key: 'cartItemID',
          label: '',
          hidden: true,
          class: 'd-none'
        },
        {
          key: 'amount',
          label: 'Aantal',
          class: 'text-center'
        },
        {
          key: 'total',
          class: 'pr-0 text-right',
          label: 'Totaal'
        }
      ]
    }
  },
  computed: {
    cartCache: function() {
      return this.$store.getters.cartCache
    },
    subTotal: function() {
      const sumPrice = this.items
        .map(item => item.total)
        .reduce((a, c) => {
          return a + c
        })

      return (Math.round(sumPrice * 100) / 100).toFixed(2).replace('.', ',')
    },
    user: function() {
      return this.$store.getters.user
    }
  },
  async beforeMount() {
    this.items = this.cartCache.CartItems
    const cart = await getCart()
    this.items = cart

    this.retrievalDate = moment(
      this.$store.getters.cartCache.DateTimeBusinessStart
    ).format('DD-MM-YYYY')
    this.returnDate = moment(
      this.$store.getters.cartCache.DateTimeBusinessEnd
    ).format('DD-MM-YYYY')
    this.warehouse = this.cartCache.WarehouseID
  },

  created() {
    this.orderer.houseNumber = ''
    this.orderer.city = ''
    this.orderer.postal = ''
    this.orderer.email = this.user.Email
    this.orderer.firstname = this.user.FirstName
    this.orderer.lastname = this.user.LastName
    this.orderer.tel = this.user.Tel
    this.orderer.street = ''
  },

  methods: {
    checkout: async function() {
      this.checkoutLoading = true

      const reservation = await checkout({
        reserverationDetails: this.reserverationDetails,
        notes: this.notes,
        CUST_ActivityDescription: this.CUST_ActivityDescription
      })
      this.$store.commit('setLatestReservation', {
        reservation
      })
      this.items = await getCart({ notes: this.notes })

      if (this.items.length === 0) {
        this.$store.commit('rentalInformation/reset')
      }

      this.$router.push({
        name: 'reservation-confirmation',
        props: { fields: this.fields, reservation }
      })

      this.checkoutLoading = false
    }
  }
}
</script>
<style>
.button-disabled {
  pointer-events: none;
}
</style>
